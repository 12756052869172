.headers {
    margin-top: 20px;
    margin-bottom: 20px;
}

.logo-zone {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo-image {
    max-width: 300px;
}

.contact-zone {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.menu-zone {
    margin-top: 30px;
}

.nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    list-style: none;
}

.nav-bar-link {
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
    display: block;
}

.toggle-menu-button-zone {
    display: none;
}

.toggle-menu-button {
    color: '#2b2b2b';
    font-size: 40px;
    border-radius: 10px;
    background-color: #fff;
}

.toggle-menu-button:hover {
    color: 'grey';
    background-color: grey;
    cursor: pointer;
}

.social-zone {
    display: none;
}

@media(max-width:980px) {

    .logo-zone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-zone {
        display: none;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .menu-zone {
        /* display: none; */
        height: 100%;
    }

    .menu-zone-active {
        height: 100%;
    }

    .nav-bar {
        width: 300px;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        top: 35px;
        padding-top: 50px;
        left: -300px;
        background-color: #f2f2f2;
        height: 100%;
        justify-content: flex-start;
        padding-right: 20px;
        animation: navbar-animation-close .4s;
    }

    @keyframes navbar-animation-close {
        0% {
            left: 0px;
        }

        100% {
            left: -300px;
        }
    }

    .nav-bar-active {
        width: 300px;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        top: 35px;
        padding-top: 50px;
        left: 0;
        background-color: #f2f2f2;
        height: 100%;
        justify-content: flex-start;
        padding-right: 20px;
        animation: navbar-animation-open .6s;
        list-style: none;
    }

    @keyframes navbar-animation-open {
        0% {
            left: -300px;
        }

        100% {
            left: 0px;
        }
    }

    .nav-bar-item :hover {
        background-color: #2f2f2f;
    }

    .nav-bar-link {
        padding: 10px;
        border-radius: 5px;
        display: block;
    }

    .nav-bar-link-active {
        padding: 10px;
        border-radius: 5px;
        text-decoration: none;
        display: block;
    }

    .toggle-menu-button-zone {
        display: flex;
        position: absolute;
        left: 300px;
        top: 0;
    }


    .toggle-menu-button-zone-active {
        display: flex;
        position: absolute;
        left: 260px;
        top: 0;
    }

    .toggle-menu-button-active {
        left: 300px;
        font-size: 40px;
    }

    .social-zone {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
    }

    .social-zone a {
        cursor: pointer;
    }

    .social-zone a :hover {
        background-color: grey;
    }

}